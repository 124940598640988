<template>
  <svg
    viewBox="0 0 800 800">
    <path
      :fill="color || '#99CCEE'"
      d="M562.875,378.8 L562.875,506.561 L598.893,506.561 L598.893,420.997 L599.198756,422.457717
      C609.511229,470.054681 651.539165,505.83208 702.052061,506.549999 L703.601,506.561
      C746.096,506.561 782.793,481.747 800,445.819 L800,445.819 L800,800 L0,800 L0,506.56
      L70.821,506.56 C102.506,506.56 130.964,492.76 150.528,470.849 C162.893,457.002
      171.698,439.911 175.521,421.014 C185.387,469.817 228.514,506.561 280.225,506.561
      C331.942,506.561 375.075,469.809 384.933,420.997 L384.933,420.997 L384.933,506.561
      L420.336,506.561 L420.336,378.8 L491.761,450.222 L562.875,378.8 Z M703.601,328.451
      C742.969514,328.451 774.884,360.365486 774.884,399.734 C774.884,439.102514 742.969514,471.017
      703.601,471.017 C664.232486,471.017 632.318,439.102514 632.318,399.734 C632.318,360.365486
      664.232486,328.451 703.601,328.451 Z M280.225,328.451 C319.593514,328.451 351.508,360.365486
      351.508,399.734 C351.508,439.102514 319.593514,471.017 280.225,471.017 C240.856486,471.017
      208.942,439.102514 208.942,399.734 C208.942,360.365486 240.856486,328.451 280.225,328.451 Z
      M75.487,328.619 C112.678,331.025 142.104,361.935 142.104,399.733 C142.104,437.542
      112.665,468.457 75.461,470.849 L75.461,470.849 L35.403,470.849 L35.403,328.619 Z M800.001,0
      L800.001,353.648 C782.794,317.721 746.097,292.907 703.602,292.907 C651.884,292.907
      608.754,329.659 598.894,378.471 L598.894,378.471 L598.894,292.907 L491.762,399.732
      L384.933,292.907 L384.933,378.471 C375.075,329.659 331.942,292.907 280.225,292.907
      C228.513,292.907 185.386,329.65 175.521,378.453 C171.698,359.557 162.893,342.467
      150.53,328.619 C130.966,306.708 102.508,292.907 70.822,292.907 L70.822,292.907 L0,292.907
      L0,0 L800.001,0 Z">
    </path>
  </svg>
</template>

<script>
export default {
  name: 'DomoLogo',

  props: {
    color: {
      type: String,
      default: null,
    },
  },
};
</script>
